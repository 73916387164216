.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.projects__container {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  border-radius: 1em;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.projects__container:hover {
  transform: translateY(-7px);
}

.projects__description {
  margin-top: 1em;
}

.projects__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em 0;
}

.projects__stack-item {
  margin: 0.5em;
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.projects__container .link {
  margin-left: 0.5em;
}

.projects__image {
  margin: 1em 0;
  width: 100%;
}
