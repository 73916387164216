.spotify-title {
  text-align: center;
  margin: 1em;
}

.spotify {
  border-radius: 15px;
  width: 60%;
  margin: 1em auto;
  display: block;
}

@media only screen and (max-width: 480px) {
  .spotify {
    width: 100%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .spotify {
    width: 75%;
  }
}
