header {
  height: 6em;
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .header {
    height: 6em;
  }
}

header,
nav ul {
  display: flex;
  align-items: center;
}

nav button {
  margin: 0;
}

nav li:hover,
nav button:hover {
  color: var(--clr-primary);
}

.nav__list {
  margin-right: 1.5em;
}

.app .nav__hamburger {
  display: none;
}

@media (max-width: 600px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }

  nav li {
    margin-top: 1.5em;
  }
}
