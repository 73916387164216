.about {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about__role {
  margin-top: 1em;
}

.about__desc {
  font-size: 1.1rem;
  max-width: 600px;
  text-align: center;
  margin-top: 2em;
}

.headshot {
  width: 15%;
  border-radius: 50%;
  border: solid black 0.2em;
  background-color: var(--headshot);
  margin-top: 1em;
}

@media only screen and (max-width: 480px) {
  .headshot {
    width: 50%;
  }
}
