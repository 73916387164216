.contact {
  flex-direction: column;
  text-align: center;
  margin: 2em;
}

button {
  padding: 0.8em 1.6em;
  margin-bottom: 2em;
  background-color: var(--clr-bg-alt);
  border-radius: 2em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.contact-items {
  display: flex;
  align-items: center;
  display: inline-block;
}

.contact-item {
  margin-left: 1em;
  vertical-align: text-top;
}

.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 4em;
  background-color: transparent;
}

@media only screen and (max-width: 480px) {
  .scroll-top {
    display: none;
  }
}
