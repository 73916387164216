.app {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
}

main {
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 0.75em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.light {
  background-color: rgb(240, 231, 219);
  color: #333;
  --clr-bg: #f0e9df;
  --clr-bg-alt: #f5eedf;
  --clr-fg: #555;
  --clr-fg-alt: #333;
  --clr-primary: #926640;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --github-bg: white;
  --headshot: cyan;
}

.dark {
  background-color: #23283e;
  color: #f3f0f5;
  --clr-bg: #23283e;
  --clr-bg-alt: #33384e;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #a09fff;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px;
  --github-bg: #0d1117;
  --headshot: #a09fff;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link--nav {
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
  margin: 1em;
}
