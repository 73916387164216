.github {
  width: 50%;
  margin: 4em auto;
  padding: 2em 4em;
  background: var(--github-bg);
  border-radius: 1.5em;
}

@media only screen and (max-width: 480px) {
  .github {
    width: 100%;
    margin: 2em auto;
    padding: 2em 2em;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .github {
    width: 66%;
    margin: 2em auto;
    padding: 2em 2em;
  }
}
